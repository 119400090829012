.carousel {
  position: relative;
  width: 100%;
}

.carousel__dot-group {
  position: absolute;
  bottom: 60px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.carousel__dot {
  width: 7px;
  height: 7px;
  margin: 5px;
  border-radius: 50%;
  background: #fff;
  opacity: 0.5;
}

.carousel__dot.carousel__dot--selected {
  opacity: 0.75;
}
